import { forwardRef } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import type { InputFieldBaseProps } from './InputFieldBase';
import InputFieldBase from './InputFieldBase';
import type { InputFieldEnum } from '@/utils/input-field-utils';
import { validateInput } from '@/utils/input-field-utils';

export type InputFieldProps = {
  name: string;
  onChangeCallback?: InputFieldBaseProps['onChange'];
  isRequired?: boolean;
  forceOptional?: boolean;
  customValidator?: Function;
  customWarning?: (value: unknown) => string | undefined;
  label?: string;
  tooltip?: string;
} & Omit<InputFieldBaseProps, 'value' | 'name' | 'onChange' | 'defaultValue'>;

const InputField = forwardRef(
  (
    {
      name,
      isRequired,
      forceOptional,
      customValidator,
      customWarning,
      type,
      label,
      tooltip,
      onChangeCallback,
      ...inputFieldProps
    }: InputFieldProps,
    forwardedRef,
  ) => {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value: string | number) =>
            validateInput({
              type: type as InputFieldEnum,
              value,
              isRequired,
              forceOptional,
              customValidator,
            }),
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <InputFieldBase
              {...field}
              {...inputFieldProps}
              label={label}
              tooltip={tooltip}
              isRequired={isRequired}
              type={type}
              onChange={(value) => {
                const e = {
                  target: {
                    value,
                  },
                };
                field.onChange(e);
                onChangeCallback?.(e);
              }}
              error={error?.message}
              warning={customWarning && customWarning(field.value)}
              ref={forwardedRef}
              forceOptional={forceOptional}
            />
          );
        }}
      />
    );
  },
);

export default InputField;
